import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import PostTile from '../components/Blog/PostTile'
import TagText from '../components/Blog/TagText'
import Pagination from '../components/Blog/Pagination'
import { PostWrapper, PostsContainer, Breadcrumb } from './post'

const TagWrapper = PostWrapper.withComponent('div')

const TagContainer = PostsContainer.withComponent('div')

const BreadcrumbChevron = styled('span')`
  margin-right: 10px;
`

const TagHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 40px;
`
const TagHeader = styled('h1')`
  font-size: 30px;
  font-family: ${props => props.theme.fonts.primaryMedium};
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 22px;
  }
`

const BlogPostsContainer = styled('div')``
const BlogPostsRow = styled('div')`
  margin-left: -23px;
  margin-right: -23px;
  display: flex;
  flex-wrap: wrap;
`

const Tag = props => {
  const { title, blog_post } = props.data.contentfulBlogPostTag
  const { skip, limit, currentPage, numPages, slug } = props.pageContext
  const locationState = props.location.state

  const checkIfPrevUrl =
    locationState &&
    locationState.prevPath &&
    locationState.prevPath !== window.location.pathname

  return (
    <Layout>
      <StickyNav />
      <TagWrapper>
        <TagContainer>
          <Breadcrumb>
            <BreadcrumbChevron>&lt;</BreadcrumbChevron>
            <Link to={checkIfPrevUrl ? locationState.prevPath : '/blog'}>
              Back to Blog
            </Link>
          </Breadcrumb>
          <TagHeaderWrapper>
            <TagHeader>
              Viewing posts related to:{' '}
              <TagText title={title} slug={slug} disabled={true} />
            </TagHeader>
          </TagHeaderWrapper>
          <BlogPostsContainer>
            <BlogPostsRow>
              {blog_post.slice(skip, limit * currentPage).map(post => (
                <PostTile key={post.id} {...post} />
              ))}
            </BlogPostsRow>
          </BlogPostsContainer>
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            url={`/tag/${slug}/`}
          />
        </TagContainer>
      </TagWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPostTag(slug: { eq: $slug }) {
      title
      blog_post {
        id
        title
        slug
        readingTime
        description
        date(formatString: "MMMM D, YYYY")
        image {
          sizes(maxWidth: 472, maxHeight: 312, cropFocus: CENTER) {
            ...GatsbyContentfulSizes
          }
        }
      }
    }
  }
`

export default Tag
